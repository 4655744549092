'use client'

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Link from "next/link";
import moment from "moment";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { ArrowSwapVertical, Building4, House2, Magicpen, WalletAdd } from "iconsax-react";
import { usePathname, useRouter } from "next/navigation";
import { useSelector, useDispatch } from 'react-redux';

import Api from "@/configs/Api";
import { hanselTimeout } from "@/configs/MasterData";
import { clearAuthData, getAccessToken, getFcmToken, getIsRegistered, getUserData, setAuthData } from '@/core/store/reducer/authSlice';
import { setNeedSignIn } from '@/core/store/reducer/loginSlice';
import { logEventNudges } from "@/core/helpers/analytics";

import BackgroundMenu from '@/images/product/bg-menu-ajukan-kpr.png'
import idealLogo from "@/images/logo/IDEAL.svg";

import ImageComponent from "@/core/components/Image";

const Navbar = ({
  enableHeaderIdealPay,
  enableIdealPay,
  showMenu = true,
}) => {

  const [isSticky, setSticky] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showMenuDropDown, setShowMenuDropDown] = useState(null);
  const [hoverAjukanKpr, setHoverAjukanKpr] = useState(null);

  // Check auth
  const accessToken = useSelector(getAccessToken);
  const dataUser = useSelector(getUserData);
  const isRegistered = useSelector(getIsRegistered);
  const fcmToken = useSelector(getFcmToken);

  const dispatch = useDispatch();

  const router = useRouter();
  const pathname = usePathname();

  const path = pathname.split("/");

  const shadowNav = ["about"];

  const navigation = [
    { name: "KPR Finder", href: "/kpr-finder", hightlight: true, page: ["kpr-finder"], show: showMenu },
    { name: "Product", href: "/product", page: ["product"], dropdown: 'product', show: showMenu },
    { name: "IDEAL Pay", href: "/ideal-pay", page: ["ideal-pay"], show: showMenu && +enableHeaderIdealPay === 1 && +enableIdealPay === 1 },
    { name: "FAQ", href: "/faq", page: ["faq"], show: showMenu },
    { name: "Partnership", href: "/partnership", page: ["partnership"], show: showMenu },
    // { name: "Referral", href: "/referral", page: ["referral"], show: showMenu },
    { name: "Article", href: "/learn", page: ["learn"], show: showMenu },
  ];

  const refButtonSignin = useRef(null);
  const refNudgesTimeout = useRef(null);

  const CheckNudges = () => {
    refNudgesTimeout.current = setTimeout(() => {
      logEventNudges('HP_NAV_Btn_Signin', {
        'is_registered': isRegistered,
        'page_url': window?.location?.href
      });

      console.log('Load Hansel Run Button Sign in');
    }, hanselTimeout);
  }

  useEffect(() => {
    if (refButtonSignin?.current) {
      if (sessionStorage.getItem('firstLoad') === '1') {
        CheckNudges();
      } else {
        window.addEventListener('load', CheckNudges);
      }
    }

    return () => {
      window.removeEventListener('load', CheckNudges);

      clearTimeout(refNudgesTimeout?.current);
    }
  }, [refButtonSignin?.current]);

  useEffect(() => {
    CheckAuth();
  }, [path]);

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
    window.addEventListener("resize", closeDropdown);

    return () => {
      window.removeEventListener("resize", closeDropdown);
      window.removeEventListener("scroll", changeBackground);
    }
  }, []);

  const CheckAuth = async () => {
    if (accessToken) {
      if (accessToken && +moment()?.unix() > jwtDecode(accessToken)?.exp) {
        const response = await doRefreshToken(dataUser?.refreshToken, dataUser?.memberUID);

        if (response?.data) {
          const resData = response?.data;
          dispatch(setAuthData(resData));
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } else if (accessToken && +moment()?.unix() <= jwtDecode(accessToken)?.exp) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
  }

  const closeDropdown = () => {
    setShowMenuDropDown(null);
  }

  const doRefreshToken = async (refreshToken, memberUID) => {
    try {
      const response = await axios
        .request({
          url: Api.REFRESH_TOKEN(),
          method: 'post',
          data: {
            refreshToken,
            memberUID,
            fcmToken: fcmToken,
          },
          headers: Api.AXIOS.defaults.headers,
        });
      return response;
    } catch (_error) {
      dispatch(clearAuthData());
    }
  }

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const setMenuState = () => {
    setOpen(!isOpen);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const onClickSignIn = () => {
    closeMenu();
    dispatch(setNeedSignIn(true));

    logEventNudges('HP_NAV_Btn_Signin_Click');
  };

  const getAjukanKprInfo = () => {
    let info = '';
    switch (hoverAjukanKpr) {
      case 'primary':
        info = 'Pembiayaan unit properti baru';
        break;
      case 'secondary':
        info = 'Biayai pembelian rumah tanpa melalui developer';
        break;
      case 'takeover':
        info = 'Pindah bank pinjaman properti kamu untuk dapatkan penawaran terbaik';
        break;
      case 'multiguna':
        info = 'Ajukan pinjaman dengan jaminan sertifikat properti';
        break;
      case 'ideal pass':
        info = 'Yuk cari tau perkiraan total pinjaman KPR yang bisa kamu dapat sebelum mencari rumah impian';
        break;
    }
    return info;
  }

  return <>
    <header
      className={`fixed w-[94%] mx-[3%] mt-[3%] rounded xl:mt-0 xl:w-full xl:mx-0 xl:rounded-none transition duration-300 ease-in-out top-0 z-50 ${isSticky
        ? "bg-white shadow-lg"
        : (['/', '/home', '/ideal-pay', '/product/multiguna']?.includes(pathname))
          ? 'bg-transparent'
          : shadowNav.includes(path[1])
            ? "xl:bg-transparent xl:bg-gradient-to-b xl:from-gray-400 xl:to-transparent"
            : "bg-white"
        }`}
    >
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 xl:px-8" aria-label="Top">
        <div className="w-full py-2 xl:py-3 flex flex-row-reverse xl:flex-row items-center justify-between relative">
          <button type="button"
            onClick={setMenuState}
            className="p-4 space-y-1 block xl:hidden text-start"
          >
            <span className="block w-6 h-0.5 bg-gradient-to-r from-success-50 to-info-70"></span>
            <span className="block w-6 h-0.5 bg-gradient-to-r from-success-50 to-info-70"></span>
            <span className="block w-6 h-0.5 bg-gradient-to-r from-success-50 to-info-70"></span>
          </button>
          <div className="flex items-center">
            <Link href="/" onClick={closeMenu}>

              <span className="sr-only">Ideal</span>
              <ImageComponent
                className="h-8 xl:h-14 w-auto"
                src={idealLogo}
                alt="Ideal"
                title="ideal"
                priority={true}
                height={56}
                width={128}
              />

            </Link>
          </div>
          <div className="ml-10 space-x-12 hidden xl:block">
            {navigation?.filter(item => item?.show).map((link, index) => (
              <div key={index} className="relative inline-block navbar-item">
                {link.hightlight ?
                  <div className="absolute bg-danger-40 -right-4 px-[5px] py-[1px] -top-2 rounded-3xl">
                    <p className="leading-3 text-[8px] text-white">Baru!</p>
                  </div>
                  : null
                }
                <Link
                  key={link.name}
                  href={link.href}
                  className={`font-bold text-lg hover:text-success-50 [&>*>*]:hover:bg-success-50 relative pb-2 transition duration-300 ease-in-out ${link.page.includes(path[1]) ? 'text-success-50' : (shadowNav.includes(path[1]) && !isSticky)
                    ? "text-white"
                    : "text-black"
                    } z-[1]`}
                  onClick={(evt) => {
                    if (link?.dropdown) {
                      evt.preventDefault();
                      // setShowMenuDropDown(prevState => prevState ? false : link?.dropdown);
                    }
                  }}>
                  {link.name}
                  {link?.dropdown && (
                    <div className="w-4 overflow-hidden inline-block ml-2">
                      <div className={`h-2.5 w-2.5 ${link.page.includes(path[1]) ? 'bg-success-50' : 'bg-neutral-90'} -rotate-45 transition-all duration-300 transform origin-top-left rounded-[2px]`}></div>
                    </div>
                  )}

                </Link>

                {link?.dropdown && (
                  <div className={`ajukan-kpr-dropdown navbar-dropdown absolute max-h-[336px] -left-[140px] top-[32px] w-[614px] pt-[28px] hidden`}>
                    <div className="bg-white w-full flex items-inherit overflow-hidden rounded-[16px]">
                      <div className="overflow-auto w-1/2">
                        <div className="relative">
                          <Link
                            href="/product/primary"
                            className="block px-6 py-7 rounded-tl-[16px] hover:bg-neutral-15 transition duration-300 ease-in-out"
                            onMouseEnter={() => setHoverAjukanKpr('primary')}
                            onMouseLeave={() => setHoverAjukanKpr(null)}
                            onClick={() => setShowMenuDropDown(null)}>

                            <p className="text-xl font-medium">KPR Properti Baru</p>
                            <div className="flex items-center gap-2">
                              <div className="bg-secondary-40 flex h-[24px] items-center justify-center rounded-full w-[24px]">
                                <Building4 className="h-[14px] w-[14px]" color="#FFFFFF" variant="Outline" />
                              </div>
                              <strong className="text-sm text-neutral-60 font-bold">Primary KPR</strong>
                            </div>

                          </Link>
                        </div>
                        <div className="relative">
                          <Link
                            href="/product/secondary"
                            className="block px-6 py-7 hover:bg-neutral-15 transition duration-300 ease-in-out"
                            onMouseEnter={() => setHoverAjukanKpr('secondary')}
                            onMouseLeave={() => setHoverAjukanKpr(null)}
                            onClick={() => setShowMenuDropDown(null)}>

                            <p className="text-xl font-medium">KPR Properti Secondary</p>
                            <div className="flex items-center gap-2">
                              <div className="bg-success-50 flex h-[24px] items-center justify-center rounded-full w-[24px]">
                                <House2 className="h-[14px] w-[14px]" color="#FFFFFF" variant="Outline" />
                              </div>
                              <strong className="text-sm text-neutral-60 font-bold">Secondary KPR</strong>
                            </div>

                          </Link>
                        </div>
                        <div className="relative">
                          <Link
                            href="/product/takeover"
                            className="block px-6 py-7 rounded-bl-[16px] hover:bg-neutral-15 transition duration-300 ease-in-out"
                            onMouseEnter={() => setHoverAjukanKpr('takeover')}
                            onMouseLeave={() => setHoverAjukanKpr(null)}
                            onClick={() => setShowMenuDropDown(null)}>

                            <p className="text-xl font-medium">Alihkan Pinjaman</p>
                            <div className="flex items-center gap-2">
                              <div className="bg-warning-40 flex h-[24px] items-center justify-center rounded-full w-[24px]">
                                <ArrowSwapVertical className="h-[14px] w-[14px]" color="#FFFFFF" variant="Outline" />
                              </div>
                              <strong className="text-sm text-neutral-60 font-bold">Take Over Pinjaman</strong>
                            </div>

                          </Link>
                        </div>
                        <div className="relative">
                          <Link
                            href="/product/multiguna"
                            className="block px-6 py-7 rounded-bl-[16px] hover:bg-neutral-15 transition duration-300 ease-in-out"
                            onMouseEnter={() => setHoverAjukanKpr('multiguna')}
                            onMouseLeave={() => setHoverAjukanKpr(null)}
                            onClick={() => setShowMenuDropDown(null)}>

                            <p className="text-xl font-medium">Jaminkan Sertifikat</p>
                            <div className="flex items-center gap-2">
                              <div className="bg-info-40 flex h-[24px] items-center justify-center rounded-full w-[24px]">
                                <WalletAdd className="h-[14px] w-[14px]" color="#FFFFFF" variant="Outline" />
                              </div>
                              <strong className="text-sm text-neutral-60 font-bold">Pinjaman Multiguna</strong>
                            </div>

                          </Link>
                        </div>
                        <div className="relative">
                          <Link
                            href="/product/ideal-pass"
                            className="block px-6 py-7 rounded-bl-[16px] hover:bg-neutral-15 transition duration-300 ease-in-out"
                            onMouseEnter={() => setHoverAjukanKpr('IDEAL Pass')}
                            onMouseLeave={() => setHoverAjukanKpr(null)}
                            onClick={() => setShowMenuDropDown(null)}>

                            <p className="text-xl font-medium">Cek Kemampuan Kredit</p>
                            <div className="flex items-center gap-2">
                              <div className="bg-third-60 flex h-[24px] items-center justify-center rounded-full w-[24px]">
                                <Magicpen className="h-[14px] w-[14px]" color="#FFFFFF" variant="Outline" />
                              </div>
                              <strong className="text-sm text-neutral-60 font-bold">IDEAL Pass</strong>
                            </div>

                          </Link>
                        </div>
                      </div>
                      <div className="w-1/2 bg-secondary-10 rounded-r-[16px] pt-6 px-8 relative">
                        <p>{getAjukanKprInfo()}</p>

                        <div className="absolute inset-0">
                          <ImageComponent src={BackgroundMenu} alt="Menu" height={306} width={306} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {isLoggedIn ? (
              <Link href="/user">
                <button
                  className="cursor-pointer hidden xl:inline-block py-2 px-12 rounded-full font-bold bg-success-50 text-white hover:bg-opacity-90"
                >
                  Dashboard
                </button>
              </Link>
            ) : (
              <button
                ref={refButtonSignin}
                type="button"
                className="cursor-pointer hidden xl:inline-block py-2 px-12 rounded-full font-bold bg-success-50 text-white hover:bg-opacity-90"
                onClick={onClickSignIn}
              >
                Masuk
              </button>
            )}
          </div>
        </div>

        <div className={`${isOpen ? "block" : "hidden"} xl:hidden fixed inset-0 z-[900]`}>
          <div className="w-full h-full bg-[rgba(0,0,0,0.5)] flex justify-end">
            <div className="bg-white w-full h-full sm:max-w-[420px]">
              <div className="border-b border-neutral-30 flex items-center justify-between pt-[16px] pb-[20px] px-[24px]">
                <ImageComponent
                  src={idealLogo}
                  alt="Ideal"
                  title="ideal"
                  height={32}
                  width={68}
                />

                <div className="flex items-center justify-center relative">
                  <button
                    type="button"
                    className="w-6 h-6"
                    onClick={setMenuState}
                  >
                    <img
                      src="/images/icons/close-circle-gradient.svg"
                      alt=""
                      height={24}
                      width={24}
                    />
                  </button>
                </div>
              </div>
              <ul>
                {navigation?.filter(item => item?.show).map((link) => (
                  <Fragment key={link.name}>
                    <button type="button"
                      className={`text-lg font-bold transition duration-300 ease-in-out px-8 py-5 text-start w-full ${link.page.includes(path[1])
                        ? "text-success-50"
                        : "text-black"
                        }`}
                      onClick={() => {
                        if (link?.dropdown) {
                          setShowMenuDropDown(prevState => prevState ? false : link?.dropdown);
                        } else {
                          setMenuState();
                          router?.push(link?.href)
                        }
                      }}
                    >
                      <span>{link.name}</span>

                      {link?.dropdown && (
                        <div className="w-4 overflow-hidden inline-block ml-2">
                          <div className={`h-2.5 w-2.5 ${link.page.includes(path[1])
                            ? "bg-success-50"
                            : "bg-neutral-90"
                            } -rotate-45 transform origin-top-left rounded-[2px]`}></div>
                        </div>
                      )}
                    </button>

                    {link?.dropdown && (
                      <div className={`grid gap-6 pb-5 px-12 ${link?.dropdown === showMenuDropDown ? 'block' : 'hidden'}`}>
                        <Link href={'/product/primary'} className="text-black" onClick={() => {
                          setShowMenuDropDown(null);
                          setMenuState();
                        }}>
                          Primary KPR
                        </Link>
                        <Link href={'/product/secondary'} className="text-black" onClick={() => {
                          setShowMenuDropDown(null);
                          setMenuState();
                        }}>
                          Secondary KPR
                        </Link>
                        <Link href={'/product/takeover'} className="text-black" onClick={() => {
                          setShowMenuDropDown(null);
                          setMenuState();
                        }}>
                          Take Over Pinjaman
                        </Link>
                        <Link href={'/product/multiguna'} className="text-black" onClick={() => {
                          setShowMenuDropDown(null);
                          setMenuState();
                        }}>
                          Pinjaman Multiguna
                        </Link>
                        <Link href={'/product/ideal-pass'} className="text-black" onClick={() => {
                          setShowMenuDropDown(null);
                          setMenuState();
                        }}>
                          IDEAL Pass
                        </Link>
                      </div>
                    )}
                  </Fragment>
                ))}
              </ul>

              <div className="pt-5 px-8">
                {isLoggedIn ? (
                  <Link href="/user">
                    <button
                      className="cursor-pointer py-3 px-12 rounded-full font-bold bg-success-50 text-white hover:bg-opacity-90 w-full"
                    >
                      Dashboard
                    </button>
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="cursor-pointer py-3 px-12 rounded-full font-bold bg-success-50 text-white hover:bg-opacity-90 w-full"
                    onClick={onClickSignIn}
                  >
                    Masuk
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

      </nav>
    </header>

    <style>{`
    .ajukan-kpr-dropdown > div {
      box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    }
    .navbar-item:hover .navbar-dropdown {
      display: flex!important;
    }
    `}</style>
  </>;
};

export default memo(Navbar);
